html {
  width: auto;
  margin:0;
  padding: 0;
  padding: 20px; 
}

body {

  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  

  background: linear-gradient(120deg, rgba(64,153,126,1) 0%, rgba(131,185,169,1) 16%, rgba(131,182,167,1) 48%, rgba(173,123,173,1) 86%, rgba(157,90,151,1) 96%, rgba(153,64,144,1) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  background-color: white;
  width: auto;
  height: auto;
  border-radius: 20px;
  padding-bottom: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 8px 5px;
}

.form_content{
  background-color: white;
  height: auto;
  overflow: auto;
}


.logo{
  width: 80%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: block;
  padding-left: 10%;
}

.errorMessage{
  font-size:12px;
  color: red;
  font-family: 'Roboto', sans-serif;

}

.footer {
  background-color: "#fff";
}



